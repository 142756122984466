import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Linkify from "react-linkify";
import { isDisabled } from "../../../utils/generalHelpers";
import { BackInTime } from "../backInTime/BackInTime";

const IndicatorMainValueSource = ({
  user,
  value,
  loading,
  error,
  indicatorStatus,
  updateValue,
}) => {
  const [sourceState, setSourceState] = useState(value);
  const [roleIdState, setRoleIdState] = useState(0);
  const [statusState, setStatusState] = useState(0);

  const { id, text_value } = sourceState;

  const onChange = (e) => {
    setSourceState({ ...sourceState, [e.target.name]: e.target.value });
  };

  const onBlur = async () => {
    await updateValue(sourceState);
  };

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  useEffect(() => {
    setRoleIdState(user.role);
    var status = Object.keys(indicatorStatus).length;
    if (status !== 0) {
      setStatusState(indicatorStatus[0].status);
    }
  }, [user, indicatorStatus]);
  return (
    <div className="input-group m-b-6">
      {isDisabled(statusState, roleIdState) ? (
        <Fragment>
          <div
            className="bg-grey-transparent-2 p-15 row-space-4 text-wrap height-125 overflow-auto scrollbar width-full"
          >
            <Linkify
              componentDecorator={componentDecorator}
              className="text-wrap"
            >
              {text_value}
            </Linkify>
          </div>
          <div className="clearfix"/>
        </Fragment>
      ) : (
        <div className="d-flex flex-column width-full align-items-start">
          <div className="width-full position-relative">
            <textarea
              className="form-control has-success width-full"
              rows="5"
              placeholder="Source Docs"
              name="text_value"
              value={text_value !== null ? text_value : " "}
              onChange={onChange}
              onBlur={onBlur}
              disabled={isDisabled(statusState, roleIdState)}
              id={id}
            />
            <div className="position-absolute" style={{ right: 10, bottom: 0 }}>
              <BackInTime
                disabled={(indicatorStatus[0].status !== 1)}
                user={user}
                id={value?.id}
                setValue={async (value) => {
                  setSourceState({ ...sourceState, text_value: value });

                  await updateValue({ ...sourceState, text_value: value });
                }}
              />
            </div>
            <div className="clearfix"/>
          </div>

          {error?.valueId === id && (
            <div className="text-center">
              <span className="text-danger">{error.msg} - Please try again</span>
            </div>
          )}
        </div>
      )}
      <div className="input-group-append">
        <ul className="dropdown-menu dropdown-menu-right"/>
      </div>
    </div>
  );
};
IndicatorMainValueSource.propTypes = {
  user: PropTypes.object,
  indicatorStatus: PropTypes.array,
  updateValue: PropTypes.func,
};
export default IndicatorMainValueSource;
