import React, { useReducer } from 'react';
import axios from 'axios';
import { baseUrl } from '../../config/const';
import MapContext from './mapContext';
import mapReducer from './mapReducer';
import { setAuthToken } from '../../utils/generalHelpers';
import { renderCityData, renderCountryData } from '../../utils/renderHelpers';

import { COUNTRIES_ERROR, GET_CITIES, GET_COUNTRIES } from '../types';

const MapState = props => {
  const initialState = {
    countries: null,
    cities: null,
    error: null
  };

  const [state, dispatch] = useReducer(mapReducer, initialState);

  // Get Countries for map
  const getCountries = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/location/country-city/`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: GET_COUNTRIES,
        payload: renderCountryData(res.data)
      });
    } catch (err) {
      dispatch({
        type: COUNTRIES_ERROR,
        payload: err.response.data
      });
    }
  };
  // Get Cities for map
  const getCities = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/location/city/`,
        setAuthToken(localStorage.token)
      );
      dispatch({
        type: GET_CITIES,
        payload: renderCityData(res.data)
      });
    } catch (err) {
      dispatch({
        type: COUNTRIES_ERROR,
        payload: err.response.data
      });
    }
  };

  return (
    <MapContext.Provider
      value={{
        countries: state.countries,
        cities: state.cities,
        error: state.error,
        getCountries,
        getCities
      }}
    >
      {props.children}
    </MapContext.Provider>
  );
};

export default MapState;
