import React, { useContext, useReducer } from "react";
import axios from "axios";
import { baseUrl } from "../../config/const";
import UserContext from "./userContext";
import AlertContext from "../alert/alertContext";
import userReducer from "./userReducer";
import { setAuthToken } from "../../utils/generalHelpers";
import renderCityOptions from "../../utils/renderCityOptions";

import {
  ADD_USER,
  DELETE_USER,
  GET_CITIES,
  GET_USERS,
  GET_WORKBOOKS,
  UPDATE_USER,
  USER_ERROR,
} from "../types";
import userFields from "../../components/user/userFields";

const UserState = props => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const initialState = {
    users: null,
    count: null,
    previous: null,
    next: null,
    cities_list: null,
    cities_options: null,
    error: null,
  };

  const [state, dispatch] = useReducer(userReducer, initialState);

  // Get Workbooks
  const getWorkbooks = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/cert/city-workbook-all/`,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: GET_WORKBOOKS,
        payload: renderCityOptions(res.data),
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err.response.data,
      });
    }
  };

  // Get Users
  const getUsers = async (page = null, search = null, cities = null) => {
    let url = baseUrl + "/api/user/manager/?page=" + (page || 1);
    if (search) {
      url += "&search=" + search;
    }
    if (cities) {
      url += "&cities=" + cities.join(",");
    }

    try {
      const res = await axios.get(url, setAuthToken(localStorage.token));
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err.response,
      });
    }
  };

  // Add User
  const addUser = async user => {
    try {
      //If user is Admin or Researcher, ensure they are staff and have access to vis
      if (user.role === 1 || user.role === 7) {
        user.vis = 3;
        user.is_staff = true;
      }

      // If user is admin, populate the admin_settings field
      if (user.role === 1) {
        user.admin_settings = Object.keys(userFields);
      }

      const res = await axios.post(
        `${baseUrl}/api/user/manager/`,
        user,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: ADD_USER,
        payload: res.data,
      });

      await axios.post(
        `${baseUrl}/api/password_reset/`,
        { email: user.email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      setAlert("User " + res.data.name + " was successfully added.", "success");
      return true;
    } catch (err) {
      setAlert(
        "Please make sure no fields are left blank and that the email isn't already in use.",
        "warning",
      );
      dispatch({
        type: USER_ERROR,
        payload: err.response.data,
      });
      return false;
    }
  };

  // Delete User
  const hardDeleteUser = async id => {
    try {
      await axios.delete(
        `${baseUrl}/api/user/manager/${id}/`,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: DELETE_USER,
        payload: id,
      });
      setAlert("User permanently deleted.", "success");
    } catch (err) {
      setAlert("You can't delete this user. Try archiving instead.", "warning");
    }
  };

  // Update User
  const updateUser = async (user, noAlert) => {
    if (!user.phone) {
      user.phone = "0";
    }

    const { date_created, date_updated, last_login, ...newUser } = user;
    if (!noAlert) {
      setAlert("Updating user...", "success", 2000);
    }

    try {
      const res = await axios.patch(
        `${baseUrl}/api/user/manager/${user.id}/`,
        newUser,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });

      if (!noAlert) {
        setTimeout(() => {
          setAlert("User updated successfully.", "success", 1200);
        }, 1700);
      }

      return true;
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err.response.data,
      });

      return false;
    }
  };

  const getCities = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/location/city/`,
        setAuthToken(localStorage.token),
      );
      dispatch({
        type: GET_CITIES,
        payload: renderCityOptions(res.data),
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err.response.data,
      });
    }
  };

  // Send reset email
  const sendResetEmail = async email => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      await axios.post(
        `${baseUrl}/api/password_reset/`,
        { email },
        config,
      );

      setAlert(
        "Successfully sent a password reset email to " + email,
        "success",
        5000,
      );
    } catch (err) {
      setAlert(
        "Unable to send a password reset email to " + email + ". Try again later",
        "danger",
        5000,
      );
    }
  };

  // Generate reset url
  const generateResetUrl = async email => {
    try {
      const res = await axios.post(
        `${baseUrl}/api/user/password-reset-url/`,
        { email },
        setAuthToken(localStorage.token),
      );

      return res.data.url;
    } catch (err) {
      setAlert(
        "Unable to generate a password reset email. Try again later",
        "danger",
        5000,
      );
    }
  };

  return (
    <UserContext.Provider
      value={{
        users: state.users,
        count: state.count,
        previous: state.previous,
        next: state.next,
        cities_options: state.cities_options,
        cities_list: state.cities_list,
        error: state.error,
        getUsers,
        getCities,
        addUser,
        hardDeleteUser,
        updateUser,
        getWorkbooks,
        sendResetEmail,
        generateResetUrl,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
