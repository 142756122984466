import React, { useState } from "react";
import { Radio, RadioGroup } from "react-radio-group";
import PropTypes from "prop-types";
import { isDisabled } from "../../../utils/generalHelpers";
import { BackInTime } from "../backInTime/BackInTime";

const IndicatorAuditParametersYesNo = (props) => {
  let status;
  const {
    user,
    value,
    indicatorStatus,
    updateValue,
    loading,
  } = props;

  const [yesNoState, setYesNoState] = useState(value);
  const { id, text_value } = yesNoState;

  const onChange = async (radSelect) => {
    setYesNoState({ ...yesNoState, text_value: radSelect });
    await updateValue({ ...yesNoState, text_value: radSelect });
  };

  if (indicatorStatus) {
    status = Object.keys(indicatorStatus).length;
    if (status !== 0) {
      status = indicatorStatus[0].status;
    }
  }

  const roleId = user.role;

  return (
    <div className="radio radio-css radio-inline">
      <RadioGroup
        name={`text_value${id}`}
        key={id}
        selectedValue={text_value}
        onChange={onChange}
      >
        <div className="radio radio-css radio-inline">
          <Radio
            value="yes"
            name={`radio_css_inline_${id}`}
            id={`inlineCssRadio1${id}`}
            disabled={isDisabled(status, roleId)}
          />
          <label htmlFor={`inlineCssRadio1${id}`}>Yes</label>
        </div>
        <div className="radio radio-css radio-inline">
          <Radio
            value="no"
            name={`radio_css_inline_${id}`}
            id={`inlineCssRadio2${id}`}
            disabled={isDisabled(status, roleId)}
          />
          <label htmlFor={`inlineCssRadio2${id}`}>No</label>
        </div>
        <div className="radio radio-css radio-inline">
          <Radio
            value="don't know"
            name={`radio_css_inline_${id}`}
            id={`inlineCssRadio3${id}`}
            disabled={isDisabled(status, roleId)}
          />
          <label htmlFor={`inlineCssRadio3${id}`}>Don't Know</label>
        </div>
        <div className="radio radio-css radio-inline">
          <Radio
            value="not applicable"
            name={`radio_css_inline_${id}`}
            id={`inlineCssRadio4${id}`}
            disabled={isDisabled(status, roleId)}
          />
          <label htmlFor={`inlineCssRadio4${id}`}>Not Applicable</label>
        </div>

        <div style={{ transform: "translate(0, 5px)", display: "inline-block" }}>
          <BackInTime
            disabled={(indicatorStatus[0].status !== 1)}
            user={user}
            id={value?.id}
            setValue={onChange}
          />
        </div>
      </RadioGroup>
    </div>
  );
};
IndicatorAuditParametersYesNo.propTypes = {
  user: PropTypes.object,
  value: PropTypes.object,
  updateValue: PropTypes.func,
  indicatorStatus: PropTypes.array,
};
export default IndicatorAuditParametersYesNo;
