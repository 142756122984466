import React, { useContext, useEffect } from "react";
import Tooltip from "react-tooltip-lite";
import axios from "axios";
import { baseUrl } from "../../../config/const";
import { setAuthToken } from "../../../utils/generalHelpers";
import { BackInTimeContext } from "../dash/IndicatorDash";

const fetchValue = async (id) => {
  try {
    const res = await axios.get(
      `${baseUrl}/api/cert/previous-year-value/?id=${id}`,
      setAuthToken(localStorage.token),
    );
    if (res.data) {
      return res.data;
    }
  } catch (err) {
  }

  return {};
};

export const BackInTime = ({ user, id, setValue, disabled, isMain, customFetcher }) => {
  const {
    addLoading,
    removeLoading,
    loading,
    fetching,
    fetchAll,
  } = useContext(BackInTimeContext);

  const getValue = () => {
    addLoading(id);
    fetchValue(id)
      .then(async ({ value, year, id: previousId }) => {
        if (value) {
          await setValue(value, year, previousId);
        }

        removeLoading(id);
      });
  };

  const onClick = async () => {
    if (loading) {
      return;
    }

    if (customFetcher) {
      addLoading(id);
      await customFetcher(fetchValue);
      removeLoading(id);

      return;
    }

    if (isMain) {
      fetchAll();
    } else {
      getValue();
    }
  };

  useEffect(() => {
    if (fetching && !isMain && !disabled) {
      getValue();
    }
    // eslint-disable-next-line
  }, [fetching]);

  // eslint-disable-next-line
  if (user?.role !== 1 || disabled || !isMain && (!id || (!setValue && !customFetcher))) {
    return <></>;
  }

  let tooltip = "Please wait...";
  if (!loading) {
    tooltip = isMain ? "Click to fill ALL values from previous year" : "Click to fill with value from previous year";
  }

  return (
    <div className="d-inline-block px-2 cursor-pointer" onClick={onClick}>
      <Tooltip
        className="d-flex flex-column justify-content-center height-full"
        content={tooltip}>
        <svg fill={loading ? "gray" : "black"}
             className="height-20 width-20"
             viewBox="0 0 20 20"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11 1.799c-4.445 0-8.061 3.562-8.169 7.996V10H.459l3.594 3.894L7.547 10H4.875v-.205C4.982 6.492 7.683 3.85 11 3.85c3.386 0 6.131 2.754 6.131 6.15S14.386 16.15 11 16.15a6.099 6.099 0 0 1-3.627-1.193l-1.406 1.504A8.13 8.13 0 0 0 11 18.199c4.515 0 8.174-3.67 8.174-8.199S15.515 1.799 11 1.799zM10 5v5a1.01 1.01 0 0 0 .293.707l3.2 3.2c.283-.183.55-.389.787-.628L12 11V5h-2z"/>
        </svg>
      </Tooltip>
    </div>
  );
};