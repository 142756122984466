import {
  ADD_CERT,
  ADD_COMMENT,
  CITY_ERROR,
  CLEAR_ERRORS,
  DELETE_ATTACHMENT,
  DELETE_COMMENT,
  GET_CHILD_INDICATORS,
  GET_CITY,
  GET_HEADER_PARAMS,
  GET_INDICATOR,
  GET_ISOTHEME,
  GET_PEER_INDICATORS,
  GET_STATUS,
  GET_VALUE_TYPE,
  INDICATOR_ERROR,
  ISOTHEME_ERROR,
  SET_INDICATORS_PARAMS,
  SET_MODAL, SET_UPLOADS,
  STATUS_ERROR,
  UPDATE_STATUS,
  UPDATE_VALUE,
  UPDATE_WORKBOOK,
  UPLOAD_ATTACHMENT,
  UPLOAD_PROGRESS,
  VALUE_ERROR,
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case GET_PEER_INDICATORS:
      return {
        ...state,
        peerIndicatorsObject: action.payload,
        loading: false,
      };
    case GET_CHILD_INDICATORS:
      return {
        ...state,
        childIndicators: action.payload,
        loading: false,
      };
    case GET_HEADER_PARAMS:
      return {
        ...state,
        themeLabel: action.pl_theme_label,
        indicatorLabel: action.pl_indicator_label,
        indicatorType: action.pl_indicator_type,
        indicatorValueType: action.pl_indicator_value_type,
        isParent: action.pl_indicator_is_parent,
        hasButton: action.pl_indicator_has_button,
        loading: false,
      };
    case GET_ISOTHEME:
      return {
        ...state,
        isotheme: action.payload,
        isoLabel: action.pl_iso_code,
        themeIndicator: action.pl_theme_indicator,
        countObject: action.pl_countObject,
        loading: false,
      };
    case ISOTHEME_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_INDICATOR:
      return {
        ...state,
        indicatorObject: action.payload,
        commentsObject: action.pl_comments,
        attachmentsObject: action.pl_attachments,
        mainValueID: action.pl_main_value_id['id'],
        mainValueCode: action.pl_main_value_id['code'],
        loading: false,
      };
    case INDICATOR_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_STATUS:
      return {
        ...state,
        indicatorStatus: action.payload,
        loading: false,
      };
    case UPDATE_STATUS:
      return {
        ...state,
        indicatorStatus: action.payload,
        loading: false,
      };
    case STATUS_ERROR:
      return {
        ...state,
        indicatorStatus: null,
        error: action.payload,
      };
    case SET_INDICATORS_PARAMS:
      return {
        ...state,
        indicatorLabel: action.pl_indicator_label,
        indicatorType: action.pl_indicator_type,
        themeLabel: action.pl_theme_label,
        indicatorValueType: action.pl_indicator_value_type,
        isParent: action.pl_indicator_is_parent,
        hasButton: action.pl_indicator_has_button,
      };
    case GET_VALUE_TYPE:
      return {
        ...state,
        indicatorValueTypeObject: action.payload,
      };
    case GET_CITY:
      return {
        ...state,
        cityObject: action.payload,
        workbookObject: action.pl_workbook_details,
      };
    case UPDATE_WORKBOOK:
      const workbooks = [...state.cityObject?.workbooks];
      const index = state.cityObject?.workbooks?.findIndex(({ id }) => id === action.payload.id);
      if (index >= 0) {
        workbooks[index] = action.payload;
      }

      return {
        ...state,
        cityObject: {
          ...state.cityObject,
          workbooks
        },
        workbookObject: action.payload,
      };
    case CITY_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    // Should be named something like "child value", also wtf is it here?
    case UPDATE_VALUE:
      return {
        ...state,
        indicatorObject: state.indicatorObject.map((value) =>
          value.id === action.payload.id ? action.payload : value
        ),
        loading: false,
        valueError: null,
      };
    case VALUE_ERROR:
      return {
        ...state,
        valueError: action.payload,
      };
    case ADD_COMMENT:
      return {
        ...state,
        commentsObject: [action.payload, ...state.commentsObject],
        loading: false,
      };
    case ADD_CERT:
      return {
        ...state,
        certObject: action.payload,
        loading: false,
      };
    case DELETE_COMMENT:
      return {
        ...state,
        commentsObject: state.commentsObject.filter(
          (comment) => comment.id !== action.payload
        ),
        loading: false,
      };
    case DELETE_ATTACHMENT:
      return {
        ...state,
        attachmentsObject: state.attachmentsObject.filter(
          (attachment) => attachment.id !== action.payload
        ),
        loading: false,
      };
    case UPLOAD_ATTACHMENT:
      return {
        ...state,
        attachmentsObject: [...action.payload, ...state.attachmentsObject],
        loading: false,
      };
    case SET_UPLOADS:
      return {
        ...state,
        attachmentsObject: [...action.payload],
        loading: false,
      };
    case UPLOAD_PROGRESS:
      return {
        ...state,
        uploadPercentage: action.payload,
        uploadCount: action.payloadCount,
        loading: false,
      };
    case SET_MODAL:
      return {
        ...state,
        modalState: action.payload,
      };
    default:
      return state;
  }
};
