import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isDisabled } from '../../../utils/generalHelpers';
import { BackInTime } from "../backInTime/BackInTime";

const IndicatorAuditParametersText = ({
  user,
  value,
  indicatorStatus,
  updateValue,
  loading,
}) => {
  const [textState, setTextState] = useState(value);
  const { id, text_value } = textState;

  const onChange = e => {
    setTextState({ ...textState, text_value: e.target.value });
  };
  const onBlur = () => {
    return updateValue(textState);
  };

  if (indicatorStatus) {
    var status = Object.keys(indicatorStatus).length;
    if (status !== 0) {
      status = indicatorStatus[0].status;
    }
  }

  var roleId = user.role;

  return (
    <div className='input-group m-b-6'>
      <textarea
        disabled={isDisabled(status, roleId)}
        className='form-control'
        rows='3'
        placeholder=''
        value={text_value !== null ? text_value : ' '}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
      />

      <div className="position-absolute" style={{ right: 10, bottom: 0 }}>
        <BackInTime
          disabled={(indicatorStatus[0].status !== 1)}
          user={user}
          id={value?.id}
          setValue={(value) => {
            setTextState({ ...textState, text_value: value });
            updateValue({ ...textState, text_value: value });
          }}
        />
      </div>
    </div>
  );
};
IndicatorAuditParametersText.propTypes = {
  user: PropTypes.object,
  value: PropTypes.object,
  updateValue: PropTypes.func,
  indicatorStatus: PropTypes.array
};
export default IndicatorAuditParametersText;
