import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import IndicatorAuditParametersYesNo from './IndicatorAuditParametersYesNo';
import IndicatorAuditParametersYesNoP from './IndicatorAuditParametersYesNoP';
import IndicatorAuditParametersYear from './IndicatorAuditParametersYear';
import IndicatorAuditParametersNumber from './IndicatorAuditParametersNumber';
import IndicatorAuditParametersText from './IndicatorAuditParametersText';
import Tooltip from 'react-tooltip-lite';

const IndicatorAuditParametersItem = ({
  user,
  question,
  indicatorStatus,
  updateValue,
  loading,
  error,
}) => {
  const { text, qtype, vtype, requirements } = question;
  const current = question.value;

  const errorRender = current?.find((val) => val.id === error?.valueId) && (
    <div className="text-danger width-full p-t-2">{error.msg} - Please try again</div>
  );

  const renderSwitch = (type) => {
    switch (type) {
      case 'Audit Question':
        if (vtype === 'yes/no')
          return (
            <div className='col-lg-12 text-inverse p-b-25 p-t-15  b-b-1'>
              <div className='p-b-15'>
                <div>
                  {requirements ? (
                    <Tooltip
                      content={<div className='width-200'>{requirements}</div>}
                    >
                      <div>
                        {text.replace('(Y/N/DK/NA)', '')}{' '}
                        <i className='fa fa-info-circle'></i>
                      </div>
                    </Tooltip>
                  ) : (
                    <span className='f-w-400'>
                      {text.replace('(Y/N/DK/NA)', '')}
                    </span>
                  )}
                </div>
              </div>
              <Fragment>
                {current !== null ? (
                  <div>
                    {current.map((value) => (
                      <IndicatorAuditParametersYesNo
                        key={value.id}
                        value={value}
                        updateValue={updateValue}
                        indicatorStatus={indicatorStatus}
                        user={user}
                        loading={loading}
                      />
                    ))}

                    {errorRender}
                  </div>
                ) : (
                  <div>loading</div>
                )}
              </Fragment>
            </div>
          );
        if (vtype === 'year')
          return (
            <div className='col-lg-12 text-inverse p-b-25 p-t-15  b-b-1'>
              <div className='p-b-15'>
                <div>
                  {requirements ? (
                    <Tooltip
                      content={<div className='width-200'>{requirements}</div>}
                    >
                      <div>
                        {text} <i className='fa fa-info-circle'></i>
                      </div>
                    </Tooltip>
                  ) : (
                    <span className='f-w-400'>{text}</span>
                  )}
                </div>
              </div>
              <Fragment>
                {current !== null ? (
                  <div>
                    {current.map((value) => (
                      <IndicatorAuditParametersYear
                        key={value.id}
                        value={value}
                        updateValue={updateValue}
                        indicatorStatus={indicatorStatus}
                        user={user}
                        loading={loading}
                      />
                    ))}

                    {errorRender}
                  </div>
                ) : (
                  <div>loading</div>
                )}
              </Fragment>
            </div>
          );
        if (vtype === 'yes/no/partially')
          return (
            <div className='col-lg-12 text-inverse p-b-25 p-t-15  b-b-1'>
              <div className='p-b-15'>
                <div>
                  {requirements ? (
                    <Tooltip
                      content={<div className='width-200'>{requirements}</div>}
                    >
                      <div>
                        {text} <i className='fa fa-info-circle'></i>
                      </div>
                    </Tooltip>
                  ) : (
                    <span className='f-w-400'>{text}</span>
                  )}
                </div>
              </div>
              <Fragment>
                {current !== null ? (
                  <div>
                    {current.map((value) => (
                      <IndicatorAuditParametersYesNoP
                        key={value.id}
                        value={value}
                        updateValue={updateValue}
                        indicatorStatus={indicatorStatus}
                        user={user}
                        loading={loading}
                      />
                    ))}

                    {errorRender}
                  </div>
                ) : (
                  <div>loading</div>
                )}
              </Fragment>
            </div>
          );
        if (vtype === 'number')
          return (
            <div className='col-lg-12 text-inverse p-t-25 p-b-25'>
              <div className='p-b-15'>
                <div>
                  {requirements ? (
                    <Tooltip
                      content={<div className='width-200'>{requirements}</div>}
                    >
                      <div>
                        {text} <i className='fa fa-info-circle'></i>
                      </div>
                    </Tooltip>
                  ) : (
                    <span className='f-w-400'>{text}</span>
                  )}
                </div>
              </div>
              <Fragment>
                {current !== null ? (
                  <div>
                    {current.map((value) => (
                      <IndicatorAuditParametersNumber
                        key={value.id}
                        value={value}
                        updateValue={updateValue}
                        indicatorStatus={indicatorStatus}
                        user={user}
                        loading={loading}
                      />
                    ))}

                    {errorRender}
                  </div>
                ) : (
                  <div>loading</div>
                )}
              </Fragment>
            </div>
          );
        if (vtype === 'percent')
          return (
            <div className='col-lg-12 text-inverse p-t-25 p-b-25'>
              <div className='p-b-15'>
                <div>
                  {requirements ? (
                    <Tooltip
                      content={<div className='width-200'>{requirements}</div>}
                    >
                      <div>
                        {text} <i className='fa fa-info-circle'></i>
                      </div>
                    </Tooltip>
                  ) : (
                    <span className='f-w-400'>{text}</span>
                  )}
                </div>
              </div>
              <Fragment>
                {current !== null ? (
                  <div>
                    {current.map((value) => (
                      <IndicatorAuditParametersNumber
                        key={value.id}
                        value={value}
                        updateValue={updateValue}
                        indicatorStatus={indicatorStatus}
                        user={user}
                        loading={loading}
                      />
                    ))}

                    {errorRender}
                  </div>
                ) : (
                  <div>loading</div>
                )}
              </Fragment>
            </div>
          );
        if (vtype === 'text')
          return (
            <div className='col-lg-12 b-t-1 b-b-1 p-t-25 p-b-25 text-inverse'>
              <div className='p-b-15'>
                <div>
                  {requirements ? (
                    <Tooltip
                      content={<div className='width-200'>{requirements}</div>}
                    >
                      <div>
                        {text} <i className='fa fa-info-circle'></i>
                      </div>
                    </Tooltip>
                  ) : (
                    <span className='f-w-400'>{text}</span>
                  )}
                </div>
              </div>
              <Fragment>
                {current !== null ? (
                  <div>
                    {current.map((value) => (
                      <IndicatorAuditParametersText
                        key={value.id}
                        value={value}
                        updateValue={updateValue}
                        indicatorStatus={indicatorStatus}
                        user={user}
                        loading={loading}
                      />
                    ))}

                    {errorRender}
                  </div>
                ) : (
                  <div>loading</div>
                )}
              </Fragment>
            </div>
          );
        break;
      default:
        break;
    }
  };
  return <Fragment>{renderSwitch(qtype)}</Fragment>;
};
IndicatorAuditParametersItem.propTypes = {
  user: PropTypes.object,
  question: PropTypes.object,
  updateValue: PropTypes.func,
  indicatorStatus: PropTypes.array,
};
export default IndicatorAuditParametersItem;
