import React, { Fragment, useEffect, useState } from "react";
import { renderThemeColour } from "../../utils/renderIcon";
import PropTypes from "prop-types";
import styled from "styled-components";
import Spinner from "../layout/Spinner2";
import brandlogo from "../../assets/img/logos/WCCD_Horizontal_White.png";
import dlcloud from "../../assets/img/icons/data-export.png";
import logowm from "../../assets/img/logos/WCCD_Vertical_White.png";
import * as am4core from "@amcharts/amcharts4/core";
import { Percent } from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import { drawAgeCohortLine } from "../../utils/renderHelpers";
import { addFootNotes } from "./utils";

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_dataviz);

am4core.addLicense("CH200325900523135");

const Styles = styled.div`
  .amcharts-amexport-item {
    border: 0px solid #777;
    background: '../../assets/img/icons/data-export.png';
  }

  .amcharts-amexport-top .amcharts-amexport-item > .amcharts-amexport-menu {
    top: -30px !important;
    right: 2px;
    cursor: pointer;
  }
`;

const getMax = (filtered) => {
  let max = -99999;
  filtered.forEach(item => {
    max = item.main_value > max ? item.main_value : max;
  });
  if (max === 0) {
    return 1;
  }
  return max + (max * 0.2);
};

const getMin = (filtered) => {
  let min = 99999;
  filtered.forEach(item => {
    min = item.main_value < min ? item.main_value : min;
  });
  if (min === 0) {
    return 0;
  }
  return min - Math.abs(min * 0.1);
};

const VisChartGrouping = (props) => {
  const {
    isoName,
    // indicatorCities,
    filteredCities,
    currIndicator,
    loading,
    groupData,
    user,
  } = props;
  const [refresh, setRefresh] = useState({});

  const legendState = [];
  useEffect(() => {
    if (!loading) {
      // cap percentage type to 100%
      if (currIndicator.indicator_vtype === "%") {
        filteredCities.forEach(data => {
          if (data.main_value > 100) {
            data.main_value = Math.min(data.value, 100);
            if (user.role === 1) {
              data.customColor = "#ff0000";
            }
          }
        });
      }

      let chart = am4core.create("chartdiv1", am4charts.XYChart);
      // some extra padding for range labels
      chart.maskBullets = false;
      chart.paddingTop = 30;
      chart.paddingBottom = 30;
      chart.cursor = new am4charts.XYCursor();
      chart.zoomOutButton.disabled = true;
      chart.scrollbarY = new am4core.Scrollbar();
      chart.scrollbarY.minZoomed = 20;
      chart.scrollbarY.exportable = false;

      chart.legend = new am4charts.Legend();
      var legend = chart.legend;
      legend.parent = chart.chartContainer;
      legend.itemContainers.template.togglable = true;
      legend.marginTop = 20;

      legend.useDefaultMarker = true;
      let marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      marker.strokeWidth = 2;
      marker.strokeOpacity = 1;
      marker.stroke = am4core.color("#999");
      let markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 15;
      markerTemplate.height = 15;

      // will use this to store colors of the same items
      let colors = {};
      // chart.colors.list = [
      //   am4core.color('#fffcf9'),
      //   am4core.color('#26547c'),
      //   am4core.color('#595179'),
      //   am4core.color('#ef476f'),
      //   am4core.color('#8b4e76'),
      //   am4core.color('#f78c6b'),
      //   am4core.color('#ffd166'),
      //   am4core.color('#83d483'),
      //   am4core.color('#93a8bb'),
      //   am4core.color('#45d592'),
      // ];
      chart.colors.list = [
        am4core.color("#10b523"),
        am4core.color("#845EC2"),
        am4core.color("#FF9671"),
        am4core.color("#D65DB1"),
        am4core.color("#FFC75F"),
        am4core.color("#ff0000"),
        am4core.color("#F9F871"),
        am4core.color("#FF6F91"),
        am4core.color("#845EC2"),
        am4core.color("#FF9671"),
        am4core.color("#D65DB1"),
        am4core.color("#FFC75F"),
        am4core.color("#ff0000"),
        am4core.color("#F9F871"),
        am4core.color("#FF6F91"),
        am4core.color("#e65485"),
        am4core.color("#77db0e"),
        am4core.color("#edc500"),
        am4core.color("#3fad2a"),
        am4core.color("#e97854"),
        am4core.color("#00f485"),
        am4core.color("#ed3000"),
        am4core.color("#8facbc"),
        am4core.color("#ba17a0"),
        am4core.color("#df8fe9"),
        am4core.color("#b54354"),
        am4core.color("#ef5f22"),
        am4core.color("#42e74d"),
        am4core.color("#54d3fe"),
        am4core.color("#6b9199"),
        am4core.color("#b52477"),
        am4core.color("#f4a60e"),
        am4core.color("#b18978"),
        am4core.color("#70e7de"),
      ];
      // chart.colors.list = [
      //   am4core.color('#e65485'),
      //   am4core.color('#77db0e'),
      //   am4core.color('#edc500'),
      //   am4core.color('#3fad2a'),
      //   am4core.color('#e97854'),
      //   am4core.color('#00f485'),
      //   am4core.color('#ed3000'),
      //   am4core.color('#8facbc'),
      //   am4core.color('#ba17a0'),
      //   am4core.color('#df8fe9'),
      //   am4core.color('#b54354'),
      //   am4core.color('#ef5f22'),
      //   am4core.color('#42e74d'),
      //   am4core.color('#54d3fe'),
      //   am4core.color('#6b9199'),
      //   am4core.color('#b52477'),
      //   am4core.color('#f4a60e'),
      //   am4core.color('#b18978'),
      //   am4core.color('#70e7de'),
      // ];

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.renderer.labels.template.fill = am4core.color("white");
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.grid.template.strokeDasharray = "1,3";
      categoryAxis.renderer.labels.template.rotation = -75;
      categoryAxis.renderer.labels.template.horizontalCenter = "right";
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
      categoryAxis.dataItems.template.text = "{realName}";
      categoryAxis.adapter.add("tooltipText", function (tooltipText, target) {
        return categoryAxis.tooltipDataItem.dataContext.realName;
      });

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      drawAgeCohortLine(valueAxis, currIndicator.indicator_id);
      valueAxis.renderer.labels.template.fill = am4core.color("#999");
      valueAxis.tooltip.disabled = true;
      valueAxis.min = 0;
      if (currIndicator.indicator_vtype && currIndicator.indicator_vtype !== " ") {
        valueAxis.title.text = `(${currIndicator.indicator_vtype})`;
      }
      valueAxis.title.fill = am4core.color("#999");
      valueAxis.title.rotation = 0;
      valueAxis.title.align = "center";
      valueAxis.title.valign = "top";
      valueAxis.title.dy = 160;
      valueAxis.title.dx = 0;
      valueAxis.title.fontSize = 12;
      // single column series for all data
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());
      columnSeries.dataFields.categoryX = "category";
      columnSeries.dataFields.valueY = "value";
      // columnSeries.columns.template.width = am4core.percent(30);
      // columnSeries.columns.template.fill = chart.colors.getIndex(13);
      columnSeries.sequencedInterpolation = true;
      columnSeries.fillOpacity = 0;
      columnSeries.strokeOpacity = 1;
      columnSeries.strokeDashArray = "1,3";
      columnSeries.columns.template.width = 0.01;
      columnSeries.tooltip.pointerOrientation = "horizontal";
      columnSeries.stroke = am4core.color("#999");
      columnSeries.tooltip.getFillFromObject = false;
      columnSeries.tooltip.background.fill = am4core.color("#0f2cbc");
      columnSeries.columns.template.propertyFields.stroke = "customColor";
      var bullet = columnSeries.bullets.create(am4charts.CircleBullet);
      bullet.radius = 3;
      bullet.stroke = am4core.color("#999");
      bullet.adapter.add("fill", function (fill, target) {
        let name = target.dataItem.dataContext.realName;
        if (!colors[name]) {
          colors[name] = chart.colors.getIndex(target.dataItem.dataContext.id);
        }
        target.fill = colors[name];
        return colors[name];
      });

      let rangeTemplate = categoryAxis.axisRanges.template;
      rangeTemplate.tick.disabled = false;
      rangeTemplate.tick.location = 0;
      rangeTemplate.tick.strokeOpacity = 0.1;
      rangeTemplate.tick.length = 20;
      rangeTemplate.grid.strokeOpacity = 0.5;
      rangeTemplate.label.tooltip = new am4core.Tooltip();
      rangeTemplate.label.tooltip.dy = -10;
      rangeTemplate.label.cloneTooltip = false;

      ///// DATA
      let hasPrimaryProvider;
      let chartData = [];
      let data = groupData;
      let color_id = 0;
      // process data ant prepare it for the chart
      for (var providerName in data) {
        let providerData = data[providerName];

        // add data of one provider to temp array
        let tempArray = [];
        let count = 0;
        // add items
        color_id++;

        for (var itemName in providerData) {
          if (itemName !== "quantity") {
            count++;

            const providerDatum = providerData[itemName];

            // we generate unique category for each column (providerName + "_" + itemName) and store realName
            if (providerDatum.primaryGroup) {
              hasPrimaryProvider = true;
            }
            tempArray.push({
              id: color_id,
              category: providerName + "_" + itemName,
              realName: itemName,
              value: providerDatum.primaryGroup ? providerDatum.value : providerDatum,
              provider: providerName,
              primaryProvider: providerDatum.primaryGroup ? providerDatum.primaryGroup : undefined,
            });
          }
        }

        // sort temp array
        tempArray.sort(function (a, b) {
          if (a.realName > b.realName) {
            return 1;
          } else if (a.realName < b.realName) {
            return -1;
          } else {
            return 0;
          }
        });

        // add quantity and count to middle data item (line series uses it)
        let lineSeriesDataIndex = Math.floor(count / 2);
        tempArray[lineSeriesDataIndex].quantity = providerData.quantity;
        tempArray[lineSeriesDataIndex].count = count;
        // push to the final data
        am4core.array.each(tempArray, function (item) {
          chartData.push(item);
        });

        // create range (the additional label at the bottom)
        let range = categoryAxis.axisRanges.create();
        range.category = tempArray[0].category;
        range.endCategory = tempArray[tempArray.length - 1].category;
        range.label.text = tempArray[0].provider;
        range.label.disabled = true;
        range.label.dy = 100;
        range.label.truncate = true;
        range.label.fontWeight = "500";
        range.label.rotation = 0;
        range.label.tooltipText = tempArray[0].provider;
        // range.label.fill = am4core.color('white');
        range.label.adapter.add("maxWidth", function (maxWidth, target) {
          let range = target.dataItem;
          let startPosition = categoryAxis.categoryToPosition(
            range.category,
            0,
          );
          let endPosition = categoryAxis.categoryToPosition(
            range.endCategory,
            1,
          );
          let startX = categoryAxis.positionToCoordinate(startPosition);
          let endX = categoryAxis.positionToCoordinate(endPosition);
          return endX - startX;
        });

        legendState.push(tempArray);
        let legenddata = [];

        for (var legendItem in legendState) {
          var cityItems = legendState[legendItem];
          let colour_id;
          let item_name;
          for (var cityItem in cityItems) {
            colour_id = cityItems[cityItem].id;
            item_name = cityItems[cityItem].provider;
          }
          legenddata.push({
            name: item_name,
            fill: chart.colors.getIndex(colour_id),
          });
        }
        legend.data = legenddata;
      }

      if (hasPrimaryProvider) {
        columnSeries.tooltipText = `{realName}: {valueY}${currIndicator.indicator_vtype}\n\nPrimary peer group: {primaryProvider}\nPeer group: {provider}\n`;
      } else {
        columnSeries.tooltipText = `{realName}: {valueY}${currIndicator.indicator_vtype}\n\nPeer group: {provider}\n`;
      }

      legend.labels.template.fill = am4core.color("#999");
      let title = chart.titles.create();
      if (currIndicator.indicator_type === "3") {
        title.text = `${isoName} Profile Data ${currIndicator.iso_section}`;
      } else {
        title.text = `${isoName} Indicator ${currIndicator.iso_section}`;
      }
      title.fontSize = 12;
      title.marginBottom = 20;
      title.align = "left";
      title.fill = am4core.color("white");
      chart.data = chartData;

      // cap percentage type to 100%
      if (currIndicator.indicator_vtype === "%") {
        chart.data.forEach(data => {
          if (data.value > 100) {
            data.value = Math.min(data.value, 100);
            if (user.role === 1) {
              data.customColor = "#ff0000";
            }
          }
        });
      }

      let title2 = chart.titles.create();
      title2.text = currIndicator.indicator_name;
      if (currIndicator.question_name && !currIndicator.main_question) {
        title2.text += " - " + currIndicator.question_name;
      }
      title2.fontSize = 22;
      title2.marginBottom = 15;
      title2.fill = am4core.color(renderThemeColour(currIndicator.theme));

      title2.align = "left";
      title2.wrap = true;
      // title2.maxWidth = 500;
      title2.width = new Percent(70);
      let title3 = chart.titles.create();
      if (!groupData) {
        title3.text = "Please select city or peer grouping.";
        title3.fontSize = 25;
        title3.fill = am4core.color("white");
        title3.marginBottom = 30;
        title3.align = "left";
      }
      // last tick
      let range = categoryAxis.axisRanges.create();
      if (chart.data.length > 1) {
        range.category = chart.data[chart.data.length - 1].category;
      }
      range.label.disabled = true;
      range.tick.location = 1;
      range.grid.location = 1;

      function customizeGrip(grip) {
        grip.icon.disabled = true;
        grip.background.fillOpacity = 0.5;
      }

      customizeGrip(chart.scrollbarY.startGrip);
      customizeGrip(chart.scrollbarY.endGrip);

      const onExportEvents = [];
      addFootNotes(currIndicator, chart, onExportEvents);

      let watermark = new am4core.Image();
      watermark.href = logowm;
      chart.plotContainer.children.push(watermark);
      watermark.align = "center";
      watermark.valign = "top";
      watermark.opacity = 0.1;
      watermark.marginBottom = 35;
      watermark.width = 300;
      watermark.height = 300;

      let watermark1 = new am4core.Image();
      watermark1.href = brandlogo;
      chart.plotContainer.children.push(watermark1);
      watermark1.align = "right";
      watermark1.valign = "top";
      watermark1.opacity = 1;
      watermark1.dy = -100;
      watermark1.dx = -20;
      watermark1.width = new Percent(25);
      watermark1.height = 100;

      // Add bullets
      let image = bullet.createChild(am4core.Image);
      image.horizontalCenter = "middle";
      image.verticalCenter = "bottom";
      image.dy = 10;
      image.y = am4core.percent(100);
      image.width = 20;
      image.propertyFields.href = "bullet";
      image.tooltipText = columnSeries.columns.template.tooltipText;
      // image.propertyFields.fill = 'color';
      image.filters.push(new am4core.DropShadowFilter());

      if (filteredCities && filteredCities.length !== 0) {
        valueAxis.min = getMin(filteredCities);
        valueAxis.max = getMax(filteredCities);
        if (currIndicator.indicator_vtype === "%") {
          valueAxis.max = Math.min(valueAxis.max, 100);
        }

        chart.scrollbarY.exportable = false;
        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.items = [
          {
            label: "...",
            menu: [
              { type: "png", label: "PNG" },
              { type: "jpg", label: "JPG" },
              { type: "pdf", label: "PDF" },
              { label: "Print", type: "print" },
            ],
          },
        ];
        chart.exporting.filePrefix = `WCCD - ${isoName} Indicator ${currIndicator.iso_section}`;
        chart.exporting.menu.items[0].icon = dlcloud;
        chart.exporting.menu.background = am4core.color("#f00", 0);
        chart.exporting.menu.align = "right";
        chart.exporting.menu.verticalAlign = "top";
        chart.exporting.events.on("exportstarted", ev => {
          onExportEvents.forEach(callable => callable());
        }, this);

        // chart.exporting.backgroundColor = am4core.color('#191839');
        chart.exporting.getFormatOptions("pdf").addURL = false;

        // chart.exporting.getFormatOptions('pdf').pageOrientation = 'landscape';
        // chart.exporting.adapter.add('pdfmakeDocument', function (pdf, target) {
        //   // Add title to the beginning

        //   pdf.doc.content.unshift({
        //     backgroundColor: am4core.color('#191839'),
        //     // text: 'Regional revenue comparison',
        //     // margin: [0, 30],
        //     // style: {
        //     //   fontSize: 25,
        //     //   bold: true,
        //     // },
        //   });

        //   return pdf;
        // });
      }

      var newchart = chart;
    }
    return () => {
      if (newchart) {
        newchart.dispose();
      }
    };
    // eslint-disable-next-line
  }, [refresh, currIndicator, groupData, loading]);

  useEffect(() => {
    if (filteredCities?.length !== refresh.filteredCities) {
      setRefresh({
        ...refresh,
        filteredCities: filteredCities?.length,
      });
    }
    // eslint-disable-next-line
  }, [filteredCities]);

  return (
    <Styles>
      <div>
        {filteredCities && filteredCities.length !== 0 && !loading ? (
          <div
            id="chartdiv1"
            style={{ width: "100%", height: "700px" }}
            className="bg-wccd-darker width-full"
          ></div>
        ) : loading ? (
          <Fragment>
            <Spinner />
          </Fragment>
        ) : (
          <div
            id="chartdiv1"
            className="hidden"
            style={{ width: "0%", height: "0px", hide: true }}
          ></div>
        )}
      </div>
    </Styles>
  );
};

VisChartGrouping.propTypes = {
  loading: PropTypes.bool,
};

export default VisChartGrouping;
