import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import PageContext from '../../config/pageContext';
import AuthContext from '../../context/auth/authContext';

import activepic from './../../assets/img/login-bg/login-bg-7.jpg';
import brandlogo from './../../assets/img/logos/WCCD_Vertical_White.png';

const Password = () => {
  const pageContext = useContext(PageContext);
  const { handleSetPageSidebar, handleSetPageHeader } = pageContext;
  const authContext = useContext(AuthContext);
  const { resetPasswordRequest } = authContext;

  const [userEmail, setEmail] = useState({
    email: ''
  });

  const { email } = userEmail;

  const onChange = e =>
    setEmail({
      ...userEmail,
      [e.target.name]: e.target.value
    });

  const onSubmit = e => {
    e.preventDefault();
    const body = { email };
    resetPasswordRequest(body);
  };

  useEffect(() => {
    //   loadUser();
    handleSetPageSidebar(false);
    handleSetPageHeader(false);

    return () => {
      handleSetPageSidebar(true);
      handleSetPageHeader(true);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <div className='login-cover'>
        <div
          className='login-cover-image'
          style={{
            backgroundImage: 'url(' + activepic + ')'
          }}
        />
        <div className='login-cover-bg' />
      </div>
      <div className='login login-v2'>
        <div className='login-header'>
          <div className='m-b-50'>
            <img src={brandlogo} alt='' className='width-200' />
          </div>
          <div className='brand'>
            {/* <span className='logo'></span> <b>Color</b> Admin
              <small>responsive bootstrap 4 admin template</small> */}
          </div>
          {/* <div className='icon'>
              <i className='fa fa-lock'></i>
            </div> */}
        </div>
        <div className='login-content'>
          <form onSubmit={onSubmit} className='margin-bottom-0'>
            <div className='text-black-darker m-b-10'>
              <strong>Password Reset:</strong> please enter the email address
              associated with your account below.
            </div>
            <div className='form-group m-b-20'>
              <input
                type='email'
                name='email'
                value={email}
                onChange={onChange}
                className='form-control form-control-lg'
                placeholder='Email Address'
                required
              />
            </div>

            {/* <div className='form-group m-b-20'>
                <input
                  type='password'
                  name='password'
                  value={password}
                  onChange={onChange}
                  className='form-control form-control-lg'
                  placeholder='Password'
                  required
                />
              </div> */}

            {/* <div className='checkbox checkbox-css m-b-20'>
              <input type='checkbox' id='remember_checkbox' />
              <label htmlFor='remember_checkbox' className=' text-black-darker'>
                Remember Me
              </label>
            </div> */}
            <div className='login-buttons'>
              <input
                type='submit'
                value='Reset Password'
                className='btn btn-inverse btn-block btn-lg'
              />
            </div>
            <div className='m-t-20 text-black-darker'>
              <strong>
                <Link to='/login'>Back to Login page</Link>
              </strong>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Password;
