import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Panel, PanelBody } from "../../layout/panel/panel";
import IndicatorMainValueSwitch from "./IndicatorMainValueSwitch";
import IndicatorMainValueStatus from "./IndicatorMainValueStatus";
import Spinner from "../../layout/Spinner";
import { BackInTime } from "../backInTime/BackInTime";

const IndicatorMainValue = (props) => {
  const {
    indicatorObject,
    childIndicators,
    loading,
    valueLoading,
    valueError,
    childValueError,
    indicatorStatus,
    indicatorValueTypeObject,
    workbookId,
    indicatorId,
    updateIndicatorStatus,
    updateValue,
    valueObject,
    setValueObject,
    setCurrentNum,
    setCurrentDen,
    currentNum,
    currentDen,
    setCalcNum,
    setCurrentMainValue,
    calcNum,
    calcDen,
    currentMainValue,
    updateAndGetChildIndicators,
    updateChildValue,
    getChildIndicators,
    setCalcDen,
    isParent,
    user,
    grabTotals,
    resetTable,
    hasButton,
  } = props;

  return (
    <Fragment>
      <Panel
        theme="default"
        className={
          indicatorStatus[0].indicator === 174
            ? "height-max"
            : isParent
              ? "height-lger"
              : "height-lg"
        }
      >
        <PanelBody>
          {indicatorStatus !== null ? (
            <Fragment>
              {indicatorStatus.map((indicatorstatus) => (
                <div key={indicatorstatus.id} className="d-flex align-items-center m-b-10">
                  <IndicatorMainValueStatus
                    indicatorstatus={indicatorstatus}
                    workbookId={workbookId}
                    updateValue={updateValue}
                    updateIndicatorStatus={updateIndicatorStatus}
                    valueObject={valueObject}
                    user={user}
                    setCurrentMainValue={setCurrentMainValue}
                    currentMainValue={currentMainValue}
                  />
                  <BackInTime
                    user={user}
                    disabled={(indicatorStatus[0].status !== 1)}
                    isMain
                  />
                </div>
              ))}
            </Fragment>
          ) : (
            <span>...loading</span>
          )}
          {indicatorObject !== null && !loading ? (
            <div className="row">
              {indicatorObject
                .filter(question => question.is_visible || question.qtype === "Question")
                .map((question) => (
                  <IndicatorMainValueSwitch
                    key={question.id}
                    question={question}
                    indicatorStatus={indicatorStatus}
                    updateValue={updateValue}
                    setCurrentNum={setCurrentNum}
                    setCurrentDen={setCurrentDen}
                    setCalcNum={setCalcNum}
                    currentNum={currentNum}
                    currentDen={currentDen}
                    valueObject={valueObject}
                    setCurrentMainValue={setCurrentMainValue}
                    calcNum={calcNum}
                    calcDen={calcDen}
                    setCalcDen={setCalcDen}
                    indicatorValueTypeObject={indicatorValueTypeObject}
                    loading={loading || valueLoading}
                    error={valueError}
                    childValueError={childValueError}
                    setValueObject={setValueObject}
                    user={user}
                    isParent={isParent}
                    childIndicators={childIndicators}
                    updateAndGetChildIndicators={updateAndGetChildIndicators}
                    updateChildValue={updateChildValue}
                    getChildIndicators={getChildIndicators}
                    grabTotals={grabTotals}
                    workbookId={workbookId}
                    indicatorId={indicatorId}
                    updateIndicatorStatus={updateIndicatorStatus}
                    resetTable={resetTable}
                    hasButton={hasButton}
                  />
                ))}
            </div>
          ) : (
            <Spinner />
          )}
        </PanelBody>
      </Panel>
    </Fragment>
  );
};

IndicatorMainValue.propTypes = {
  setValueObject: PropTypes.func,
  childIndicators: PropTypes.array,
  loading: PropTypes.bool,
  isParent: PropTypes.bool,
  indicatorStatus: PropTypes.array,
  user: PropTypes.object,
  workbookId: PropTypes.string,
  updateIndicatorStatus: PropTypes.func,
  updateValue: PropTypes.func,
  getIndicatorData: PropTypes.func,
  setCurrentNum: PropTypes.func,
  setCurrentDen: PropTypes.func,
  grabTotals: PropTypes.func,
  setCalcNum: PropTypes.func,
  currentNum: PropTypes.object,
  currentDen: PropTypes.object,
  setCurrentMainValue: PropTypes.func,
  calcNum: PropTypes.object,
  calcDen: PropTypes.object,
  setCalcDen: PropTypes.func,
  indicatorValueTypeObject: PropTypes.object,
  updateAndGetChildIndicators: PropTypes.func,
};

export default IndicatorMainValue;
