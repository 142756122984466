import React, { Fragment, useEffect, useState } from 'react';
import Tooltip from 'react-tooltip-lite';
import { Radio, RadioGroup } from 'react-radio-group';
import PropTypes from 'prop-types';
import IndicatorMainValueParentChild from './IndicatorMainValueParentChild';
import { isDisabled } from '../../../utils/generalHelpers';
import { BackInTime } from "../backInTime/BackInTime";

const IndicatorMainValueParent = (props) => {
  const {
    childIndicators,
    user,
    indicatorStatus,
    table_columns,
    min_text,
    max_text,
    has_aggregate,
    info,
    other_info,
    updateAndGetChildIndicators,
    updateChildValue,
    updateIndicatorStatus,
    getChildIndicators,
    resetTable,
    error,
    valueObject,
  } = props;
  const [is_disabled, setDisabled] = useState(true);
  const [aggregate_selected, setAggregate] = useState(false);

  const onChange = (radSelect) => {
    setAggregate(radSelect);
    updateIndicatorStatus({ ...indicatorStatus[0], is_aggregate: radSelect });
    resetTable(indicatorStatus[0].indicator, indicatorStatus[0].workbook);
  };

  useEffect(() => {
    const status = Object.keys(indicatorStatus).length;
    const roleId = user.role;

    if (status !== 0 && roleId !== 0) {
      setDisabled(isDisabled(indicatorStatus[0].status, user.role));
      setAggregate(indicatorStatus[0].is_aggregate);
    }
  }, [user, indicatorStatus]);

  const fetchBackInTimeChildren = async (fetchValue) => {
    const values = childIndicators?.flatMap((child) => {
      return child?.questions
        .filter((q) => q?.is_visible && q?.qtype === 'Additional')
        .map((q) => q?.value[0]);
    });

    await Promise.all(values.map(async (value) => {
      const { value: newValue } = await fetchValue(value?.id);

      if (newValue) {
        return updateChildValue({
          ...value,
          main_value: newValue,
          calc_value: newValue,
        });
      }
    }));

    await getChildIndicators(indicatorStatus[0].indicator, indicatorStatus[0].workbook);
  }

  const width = `${1000 / table_columns.length}px`;
  return (
    <Fragment>
      <div className='row justify-content-between p-t-10 p-b-10 b-t-1'>
        <div style={{ paddingTop: '7px' }}>
          <Tooltip content={<div className='width-200'>{max_text}</div>}>
            <div className='font-weight-bold'>
              {min_text} <i className='fa fa-info-circle'/>
            </div>
          </Tooltip>
        </div>

        <BackInTime
          disabled={(indicatorStatus[0].status !== 1)}
          user={user}
          id={valueObject?.id}
          customFetcher={fetchBackInTimeChildren}
        />

        {has_aggregate && (
          <RadioGroup
            name='aggregate_switch '
            key={indicatorStatus[0].indicator}
            selectedValue={aggregate_selected}
            onChange={(radSelect) => onChange(radSelect)}
          >
            <div className='radio radio-css radio-inline'>
              <Radio
                value={false}
                name='radio_css_inline_2'
                id='inlineCssRadio1'
                disabled={is_disabled}
              />
              <label htmlFor='inlineCssRadio1'>Non-aggregate</label>
            </div>
            <div className='radio radio-css radio-inline'>
              <Radio
                value={true}
                name='radio_css_inline_2'
                id='inlineCssRadio2'
                disabled={is_disabled}
              />
              <label htmlFor='inlineCssRadio2'>Aggregate</label>
            </div>
          </RadioGroup>
        )}

        {error && (
          <div className='width-full'>
            <span className='text-danger'>{error.msg} - Please try again</span>
          </div>
        )}
      </div>
      <div style={{ width: 'max-content' }}>
        <div className='row p-t-10 b-t-1 p-b-10'>
          {!!table_columns && table_columns.map((column, i) => (
            <div
              className="f-s-11 table-cell"
              style={{ width }}
              key={i}
            >
              <Tooltip
                content={
                  <Fragment>
                    <div style={{ maxWidth: 300 }}>
                      <span>
                        {column.info ? info : column.other_info
                          ? other_info : (column.more || column.name)}
                      </span>
                    </div>
                  </Fragment>
                }
              >
                <div
                  className='font-weight-bold'
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                  }}
                >
                  {column.info || column.other_info || column.more ? (
                    <Fragment>
                      <i className='fa fa-info-circle m-r-2'/>
                      {column.name}
                    </Fragment>
                  ) : (column.name)}
                </div>
              </Tooltip>
            </div>
          ))}
        </div>
        {!!childIndicators && (
          <Fragment>
            {childIndicators
              .sort((a, b) => a.display_order - b.display_order)
              .map((childIndicator, index) => (
                <IndicatorMainValueParentChild
                  indicatorStatus={indicatorStatus}
                  user={user}
                  key={childIndicator.id}
                  childIndicator={childIndicator}
                  updateAndGetChildIndicators={updateAndGetChildIndicators}
                  width={width}
                  is_disabled={is_disabled}
                  has_aggregate={has_aggregate}
                  aggregate_selected={aggregate_selected}
                  table_columns={table_columns}
                  index={index}
                />
              ))}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};
IndicatorMainValueParent.propTypes = {
  user: PropTypes.object,
  table_columns: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  isParent: PropTypes.bool,
  updateAndGetChildIndicators: PropTypes.func,
  indicatorStatus: PropTypes.array,
  childIndicators: PropTypes.array,
};
export default IndicatorMainValueParent;
