import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { isDisabled, yearsList } from "../../../utils/generalHelpers";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { BackInTime } from "../backInTime/BackInTime";

const IndicatorMainValueDenominator = (props) => {
  const {
    user,
    value,
    indicatorStatus,
    loading,
    error,
    updateValue,
    setCurrentDen,
    currentDen,
    setCalcDen,
  } = props;

  let denominator = value;
  if (currentDen !== null) {
    denominator = currentDen;
  }

  const [denState, setDenState] = useState(denominator);
  const { id, main_value, year } = denState;

  const [dropdownDen, setOpen] = useState(false);
  const toggleDen = () => {
    setOpen(!dropdownDen);
  };

  const onClick = async (year) => {
    const updated = {
      ...denState,
      year: year,
    };
    setCurrentDen(updated);
    setDenState(updated);
    setCalcDen(updated);
    await updateValue(updated);
  };

  const onBlur = async () => {
    if (denominator !== denState) {
      setCurrentDen(denState);
      setCalcDen(denState);
      await updateValue(denState);
    }
  };

  let status;
  if (indicatorStatus) {
    status = Object.keys(indicatorStatus).length;
    if (status !== 0) {
      status = indicatorStatus[0].status;
    }
  }

  const years = yearsList();
  const roleId = user.role;

  useEffect(() => {
    setCurrentDen(null);
    setCalcDen(value);
    setDenState(value);
    // eslint-disable-next-line
  }, [value]);

  const onChange = (val) => {
    setDenState({
      ...denState,
      main_value: val,
      calc_value: val,
    });
    setCalcDen({
      ...denState,
      main_value: val,
      calc_value: val,
    });
    setCurrentDen({
      ...denState,
      main_value: val,
      calc_value: val,
    });
  };

  return (
    <div className="input-group m-b-6">
      <NumberFormat
        className="form-control has-success"
        thousandSeparator=","
        decimalSeparator="."
        disabled={isDisabled(status, roleId)}
        name="main_value"
        key={id}
        value={main_value}
        onBlur={onBlur}
        onValueChange={values => {
          let val = values.floatValue;
          if (val === undefined) {
            val = null;
          }

          onChange(val);
        }}
      />
      <div className="input-group-append btn-group">
        <button
          className="btn btn-default"
          id="caret"
          disabled={isDisabled(status, roleId)}
        >
          {year}
        </button>
        <UncontrolledButtonDropdown
          isOpen={dropdownDen}
          toggle={() => toggleDen(!dropdownDen)}
        >
          <DropdownToggle
            caret
            color="default"
            className="fa fa-calendar"
            disabled={isDisabled(status, roleId)}
          />
          <DropdownMenu>
            {years.map(year => (
              <DropdownItem onClick={async () => await onClick(year)} key={year}>
                {year}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>

      <BackInTime
        disabled={(indicatorStatus[0].status !== 1)}
        user={user}
        id={value?.id}
        setValue={async (value, year) => {
          const update = {
            ...denState,
            main_value: value,
            calc_value: value,
            year,
          };
          setDenState(update);
          setCurrentDen(update);
          await updateValue(update);
        }}
      />

      {error?.valueId === id && (
        <div className="width-full">
          <span className="text-danger">{error.msg} - Please try again</span>
        </div>
      )}
    </div>
  );
};
IndicatorMainValueDenominator.propTypes = {
  user: PropTypes.object,
  value: PropTypes.object,
  loading: PropTypes.bool,
  indicatorStatus: PropTypes.array,
  updateValue: PropTypes.func,
  setCurrentDen: PropTypes.func,
  setCalcDen: PropTypes.func,
  currentDen: PropTypes.object,
};

export default IndicatorMainValueDenominator;
