const setError = (error, alert = '') => {
  // if ((error.message = "Cannot read property 'status' of undefined"))
  //   alert = 'No values found for this Indicator.';
  if (error.status === 403) alert = error.data.detail;
  if (error.status === 401) alert = error.data.detail;
  if (error.non_field_errors) alert = error.non_field_errors.join();

  return alert;
};
export default setError;
